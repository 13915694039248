var _w = $(window);
var _wW = _w.width();

// Jump Nav for Finale pagevar $navLink = $("header nav ul li");
var $navLink = $('.js--jumpnav');
$navLink.on("click", "a", function(e){
	var $this = $(this);
	var the_href = $(this).attr("href");
	var extra_bump = $(this).data('bump');
	var extra_bump_desktop = $(this).data('bump-desktop');

	e.preventDefault();

	if(_wW > 1023){

		if(extra_bump_desktop){
			$("html, body").animate({ scrollTop: $(the_href).offset().top - extra_bump_desktop }, "slow");
		} else {
			$("html, body").animate({ scrollTop: $(the_href).offset().top - extra_bump }, "slow");
		}
	} else {
		$("html, body").animate({ scrollTop: $(the_href).offset().top - extra_bump }, "slow");
	}

	$('.js-menu').removeClass('open');
	//$('.close__menu').hide();
	//$('.js-menu').fadeOut();
	$('.js-menu-trigger').removeClass('open');
	$('body').removeClass('no-scroll');
});


$( document ).ready(function() {

	$('.js-menu-trigger').on('click', function(e){
		e.preventDefault();
		//$('.js-menu').fadeToggle().css('display', 'flex');
		$('.js-menu').toggleClass('open');
		//$('.close__menu').show();
		$(this).toggleClass('open');
		$('body').toggleClass('no-scroll');
	})

	// $('.js-menu-trigger').on('click', function(e){
	// 	e.preventDefault();
	// 	$('.js-menu').addClass('open');
	// 	$('.close__menu').show();
	// })

	// $('.js-menu-close').on('click', function(e){
	// 	e.preventDefault();
	// 	$('.js-menu').removeClass('open');
	// 	$('.close__menu').hide();
	// })

	$('.js-popup').on('click', function(e){
		e.preventDefault();
		$('.js-modal').fadeIn();
		$("html, body").animate({ scrollTop: $('header').offset().top }, "slow");
		$('.js-popup').css('opacity', '0');
		//$('body').addClass('no-scroll');
	})

	$('.js-close-modal').on('click', function(e){
		e.preventDefault();
		$('.js-modal').fadeOut();
		$('.js-popup').css('opacity', '1');
		$('body').removeClass('no-scroll');
	})


	var $status = $('.totals--1 span');
	var $slickElement = $('.js-carousel-1');

	$slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
	    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
	    var i = (currentSlide ? currentSlide : 0) + 1;
	    //$status.text(i + ' / ' + slick.slideCount);
		$status.text(i);

		if(_wW >= 1024){
			if(i == "2" || i == 4){
				$('.totals--1').addClass('dark');
			} else {
				$('.totals--1').removeClass('dark');
			}
		}
	});



	var $status2 = $('.totals--2 span');
	var $slickElement2 = $('.js-carousel-2');

	$slickElement2.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
	    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
	    var i = (currentSlide ? currentSlide : 0) + 1;
	    //$status.text(i + ' / ' + slick.slideCount);
		$status2.text(i);
	});

	// Carousels
	//********************************
	$('.js-carousel-1').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
        autoplay: true,
		autoplaySpeed: 4000,
		arrows: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
		//asNavFor: '.slider-nav'
	});

	$('.js-carousel-2').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
        autoplay: true,
		autoplaySpeed: 4000,
		arrows: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
		//asNavFor: '.slider-nav'
	});

	$('.js-carousel-3').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
        autoplay: true,
		autoplaySpeed: 4000,
		arrows: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
		//asNavFor: '.slider-nav'
	});

	$('.js-carousel-4').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
        autoplay: true,
		autoplaySpeed: 4000,
		arrows: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
		//asNavFor: '.slider-nav'
	});

	// facilities
	$('.js-carousel-5').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
        autoplay: true,
		autoplaySpeed: 4000,
		arrows: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
		//asNavFor: '.slider-nav'
	});

	// bikes
	$('.js-carousel-6').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
        autoplay: true,
		autoplaySpeed: 4000,
		arrows: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
		//asNavFor: '.slider-nav'
	});

	// $('.js-mobile-carousel').slick({
	// 	infinite: false,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	dots: true,
    //     autoplay: false,
	// 	autoplaySpeed: 4000,
	// 	arrows: false,
    //     pauseOnFocus: false,
    //     pauseOnHover: false,
    //     pauseOnDotsHover: false,
	// 	//asNavFor: '.slider-nav'
	// 	mobileFirst: true,
	// 	// responsive: [
	// 	//     {
	// 	//         breakpoint: 768,
	// 	//         settings: "unslick",
	// 	//     },
	// 	// ]
	// });

	// var viewportWidth = jQuery(window).width();

	// if (viewportWidth > 767) {
    //     $('.js-mobile-carousel').slick('unslick');
    // }

	// jQuery(window).on('resize', function() {
	//     viewportWidth = jQuery(window).width();

	//     if (viewportWidth > 767) {
	//         $('.js-mobile-carousel').slick('unslick');
	//     } else {
	//         // Do some thing
	//     }
	// });


	


	var waypointHeader = new Waypoint({
        element: $('.wrapper'),
        handler: function(direction) {
            $('.nav').toggleClass('visible');
			$('body').toggleClass('scrolling');
        },
        offset: '+44px'
    })
	// $('.js-carousel').slick({
	// 		infinite: true,
	// 		slidesToShow: 1,
	// 		slidesToScroll: 1,
	// 		dots: false,
	//         autoplay: false,
	//         pauseOnFocus: false,
	//         pauseOnHover: false,
	//         pauseOnDotsHover: false,
	// 		appendArrows: $('.carousel__wrapper')
	// 	});


	// var waypoint_menu4 = new Waypoint({
	// 	element: $('.js-clear-menu-1'),
	// 	handler: function(direction) {
	// 		//notify(this.element.id + ' triggers at ' + this.triggerPoint)
	// 		$('.js--jumpnav a').removeClass('active');
	// 		$('.js--jumpnav a.item--1').addClass('active');
	//
	// 	},
	// 	offset: '69px'
	// })
	//
	// var waypoint_menu4 = new Waypoint({
	// 	element: $('.js-clear-menu-2'),
	// 	handler: function(direction) {
	// 		//notify(this.element.id + ' triggers at ' + this.triggerPoint)
	// 		$('.js--jumpnav a').removeClass('active');
	// 		$('.js--jumpnav a.item--2').addClass('active');
	//
	// 	},
	// 	offset: '69px'
	// })
	//
	// var waypoint_menu4 = new Waypoint({
	// 	element: $('.js-clear-menu-3'),
	// 	handler: function(direction) {
	// 		//notify(this.element.id + ' triggers at ' + this.triggerPoint)
	// 		$('.js--jumpnav a').removeClass('active');
	// 		$('.js--jumpnav a.item--3').addClass('active');
	//
	// 	},
	// 	offset: '69px'
	// })
	//
	// var waypoint_menu4 = new Waypoint({
	// 	element: $('.js-clear-menu-4'),
	// 	handler: function(direction) {
	// 		//notify(this.element.id + ' triggers at ' + this.triggerPoint)
	// 		$('.js--jumpnav a').removeClass('active');
	// 		$('.js--jumpnav a.item--4').addClass('active');
	//
	// 	},
	// 	offset: '69px'
	// })
	// var waypoint_menu5 = new Waypoint({
	// 	element: $('.js-clear-menu-5'),
	// 	handler: function(direction) {
	// 		//notify(this.element.id + ' triggers at ' + this.triggerPoint)
	// 		$('.js--jumpnav a').removeClass('active');
	// 		$('.js--jumpnav a.item--5').addClass('active');
	//
	// 	},
	// 	offset: '69px'
	// })
	// var waypoint_menu6 = new Waypoint({
	// 	element: $('.js-clear-menu-6'),
	// 	handler: function(direction) {
	// 		//notify(this.element.id + ' triggers at ' + this.triggerPoint)
	// 		$('.js--jumpnav a').removeClass('active');
	// 		$('.js--jumpnav a.item--6').addClass('active');
	//
	// 	},
	// 	offset: '50%'
	// })


});
